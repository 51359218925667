import { customFields } from "@/conf/editor/confEditor.js";
import Filesaver from "file-saver";
import { SERVICE_URL } from "../../js/constants";

export class SetupEditor {
  constructor(editTemplateRef) {
    this.editTemplate = editTemplateRef;
    this.editor = this.editTemplate.editor;
    this.setupConf = this.setupConf();
  }

  setupEditor() {
    //this.addBlockToEditor(editor, this.setupConf.setupBlockProbador);
    this.addBlockToEditor(this.setupConf.setupBlockSocial);
    this.addBlockToEditor(this.setupConf.setupBlockLogoOptica);
    this.addBlockToEditor(this.setupConf.setupBlockFirmaOptico);
    this.addBlockToEditor(this.setupConf.setupBlockFormSample);
    this.addBlockToEditor(this.setupConf.setupBlockChat);

    this.updateBlockToEditor(this.setupConf.setupBlockForm);
    this.updateBlockToEditor(this.setupConf.setupBlockInput);
    this.updateBlockToEditor(this.setupConf.setupBlockTextArea);
    this.updateBlockToEditor(this.setupConf.setupBlockSelect);
    this.updateBlockToEditor(this.setupConf.setupBlockButton);
    this.updateBlockToEditor(this.setupConf.setupBlockCustomCode);

    this.addButtonToEditor(this.setupConf.setupButtonMetaTags);
    this.addButtonToEditor(this.setupConf.setupButtonEditData);
    this.addButtonToEditor(this.setupConf.setupButtonSave);

    this.addCommandSaveToEditor(this.editTemplate);
    this.addCommandEditDataToEditor(this.editTemplate);
    this.addCommandMetaTagsToEditor(this.editTemplate);
    this.addCommandExportToEditor(this.editTemplate);

    this.addRichTextToEditor(this.setupConf.setupRichTextEditorLink);
    this.addRichTextToEditor(this.setupConf.setupRichTextEditorClientFields);

    this.addDomComponents();
    this.addCheckElement(
      ["chat", "form"],
      this.editTemplate.$t(
        "views.templates.edittemplate.editor.incompatibleElements"
      )
    );
  }

  setupConf() {
    return {
      setupBlockProbador: {
        name: "Probador",
        label: "Probador",
        category: "Custom",
        attributes: {
          class: "mdi mdi-sunglasses mdi-48px",
        },
        content: {
          content: "<div id='fitmix-container'>fittingbox</div>",
          style: {
            width: "100%",
            height: "450px",
          },
        },
      },
      setupBlockSocial: {
        name: "Social",
        label: "Social",
        category: "Custom",
        attributes: {
          class: "fa fa-users",
        },
        content: {
          type: "social",
          attributes: {
            id: "sclA5461aWrTxB",
            name: "social",
          },
          style: {
            display: "block",
            width: "100%",
            height: "110px",
            "background-image": `url('https://${SERVICE_URL}/pictures/default/caja-redes-sociales.png')`,
            "background-repeat": "no-repeat",
            "background-position": "center",
          },
        },
      },
      setupButtonEditData: {
        panel: "options",
        conf: {
          id: "editDataTemplate",
          className: "fa fa-sticky-note-o",
          command: "editDataTemplate",
          attributes: {
            title: "Editar datos",
          },
        },
      },
      setupButtonSave: {
        panel: "options",
        conf: {
          id: "save",
          className: "fa fa-floppy-o",
          command: "save",
          attributes: {
            title: "Guardar",
          },
        },
      },
      setupButtonMetaTags: {
        panel: "options",
        conf: {
          id: "metaTags",
          className: "fa fa-tags",
          command: "editMetaTags",
          attributes: {
            title: "Editar datos móvil",
          },
        },
      },
      setupRichTextEditorLink: {
        id: "link",
        name: "Link",
        icon: '<span style="transform:rotate(45deg)">&supdsub;</span>',
        attributes: {
          title: "Link",
        },
        result: (rte) => rte.insertHTML(`<a href="#">${rte.selection()}</a>`),
      },
      setupRichTextEditorClientFields: {
        id: "clientFields",
        name: "ClientFields",
        icon: this.createSelectCustomFieldsClient(),
        attributes: {
          title: "Campos disponibles",
        },
        event: "change",
        result: (rte, action) => {
          var el = document.getElementById("clientFieldsSelect");
          var value = el.options[el.selectedIndex].value;
          el.value = 0;
          rte.insertHTML(value);
        },
      },
      setupBlockForm: {
        name: "form",
        content: `
                <form>
                    <div class="form-group">
                        <label for="lblCampoTexto">Campo de texto</label>
                        <input type="text" class="form-control" placeholder="Campo de texto">
                    </div>
                    <button type="submit" class="btn btn-primary btn-block">Enviar</button>
                </form>
                `,
      },
      setupBlockInput: {
        name: "input",
        content: `<input class="form-control" />`,
      },
      setupBlockTextArea: {
        name: "textarea",
        content: `<textarea class="form-control" />`,
      },
      setupBlockSelect: {
        name: "select",
        content: `<select class="form-control">
                            <option value="1">Opción 1</option>
                          </select>`,
      },
      setupBlockButton: {
        name: "button",
        content: `<button class="btn btn-primary">Enviar</button>`,
      },
      setupBlockCustomCode: {
        name: "custom-code",
        label: `<svg width="50" height="50" viewBox="0 0 25 30">
                            <path d="M14.6 16.6l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4m-5.2 0L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4z"></path>
                        </svg>
                        <div class="gjs-block-label">Custom Code</div>`,
      },
      setupBlockLogoOptica: {
        name: "logoOptica",
        label: "Logo Óptica",
        category: "Custom",
        attributes: {
          class: "fa fa-picture-o",
        },
        content: {
          type: "image",
          src: `https://${SERVICE_URL}/pictures/default/logooptica.png`,
          attributes: {
            alt: "LogoOptica",
            id: "logOptA5461aWrTxB",
          },
          style: {
            width: "100%",
            height: "100%",
            margin: "0 auto",
            display: "flex",
          },
        },
      },
      setupBlockFirmaOptico: {
        name: "firmaOptico",
        label: "Firma Óptico",
        category: "Custom",
        attributes: {
          class: "fa fa-address-card-o",
        },
        content: {
          type: "image",
          src: `https://${SERVICE_URL}/pictures/default/firmaoptico.png`,
          attributes: {
            alt: "FirmaOptico",
            id: "frmOptA5461aWrTxB",
          },
          style: {
            width: "100%",
            height: "100%",
            margin: "0 auto",
            display: "flex",
          },
        },
      },
      setupBlockFormSample: {
        name: "formSample",
        label: "Formulario ejemplo",
        category: "Forms",
        attributes: {
          class: "fa fa-indent",
        },
        content: `
                <form>
                    <div class="form-group">
                        <label for="lblCampoTexto">Campo de texto</label>
                        <input type="text" class="form-control" placeholder="Campo de texto">
                    </div>
                    <div class="form-group">
                        <label for="lblCampoEmail">Campo email</label>
                        <input type="email" class="form-control" placeholder="Campo email">
                    </div>
                    <div class="form-group">
                        <label for="lblCampoPassword">Campo password</label>
                        <input type="password" class="form-control" placeholder="Campo password">
                    </div>
                    <div class="form-group">
                        <label for="lblCampoNumerico">Campo numérico</label>
                        <input type="number" class="form-control" placeholder="Campo numérico">
                    </div>
                    <div class="form-group">
                        <label for="lblCampoFecha">Campo fecha</label>
                        <input type="date" class="form-control" placeholder="Campo fecha">
                    </div>
                    <div class="form-group">
                        <label for="lblCampoFecha">Campo hora</label>
                        <input type="time" class="form-control" placeholder="Campo hora">
                    </div>
                    <div class="form-group">
                        <label for="lblCampoSelectSimple">Campo select simple</label>
                        <select class="form-control">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="LblCampoSelectMultiple">Campo select múlitple</label>
                        <select multiple class="form-control">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="lblCampoTextarea">Campo multilínea</label>
                        <textarea class="form-control"rows="3" placeholder="Campo multilínea"></textarea>
                    </div>
                    <div class="form-group">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label" for="lblCampoRadio1">Opción 1</label>
                        <input class="form-check-input" type="radio" name="ejemploCampoRadio" value="opcion1">
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label" for="lblCampoRadio1">Opcion 2</label>
                        <input class="form-check-input" type="radio" name="ejemploCampoRadio" value="opcion2">
                    </div>
                    </div>
                    <div class="form-group">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label" for="inlineCheckbox1">Opción 1</label>
                        <input class="form-check-input" type="checkbox" value="opcion1">
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label" for="inlineCheckbox2">Opción 2</label>
                        <input class="form-check-input" type="checkbox" value="opcion2">
                    </div>
                    </div>
                    <button type="submit" class="btn btn-primary btn-block">Enviar</button>
                </form>
                `,
      },
      setupBlockChat: {
        name: "chat",
        label: "Chat Interactivo",
        category: "Custom",
        attributes: {
          class: "fa fa-comments-o",
        },
        content: {
          type: "chat",
          attributes: {
            id: "chat",
            name: "chat",
          },
          style: {
            display: "block",
            width: "100%",
            height: "600px",
            "background-image": `url('https://${SERVICE_URL}/pictures/default/optic-bot-frame.png')`,
            "background-repeat": "no-repeat",
            "background-position": "center",
          },
        },
      },
    };
  }

  createSelectCustomFieldsClient() {
    let selectHtml = `<i class="fa fa-address-card" aria-hidden="true"></i>&nbsp;<select id="clientFieldsSelect" class="clientFields">`;
    selectHtml += `<option value="0" disabled selected>${this.editTemplate.$t(
      "views.templates.edittemplate.editor.clientFields"
    )}</option>`;

    for (const key in customFields) {
      if (customFields.hasOwnProperty(key)) {
        const element = customFields[key];
        selectHtml += `<option value="${key}">${element}</option>`;
      }
    }

    selectHtml += `</select>`;

    return selectHtml;
  }

  addButtonToEditor(objConf) {
    this.editor.Panels.addButton(objConf.panel, objConf.conf);
  }

  addBlockToEditor(objConf) {
    this.editor.BlockManager.add(objConf.name, {
      label: objConf.label,
      category: objConf.category,
      attributes: objConf.attributes,
      content: objConf.content,
    });
  }

  updateBlockToEditor(objConf) {
    const block = this.editor.BlockManager.get(objConf.name);
    this.editor.BlockManager.get(objConf.name).set({
      content: objConf.content ? objConf.content : block.attributes.content,
      label: objConf.label ? objConf.label : block.attributes.label,
    });
  }

  addCommandSaveToEditor() {
    let that = this.editTemplate;
    let editor = this.editor;
    let setupConf = this;

    editor.on("run:save:before", (options) => {
      if (editor.getDevice() !== "Desktop") {
        options.abort = true;
        that.info({
          msg: that.$t("views.templates.edittemplate.editor.errorDeviceView"),
          timeout: 6000,
        });
      }
    });
    editor.Commands.add("save", {
      run: function(editor, sender) {
        sender && sender.set("active", 0);
        if (!that.template.name || !that.template.description) {
          that
            .$templateData(
              null,
              that.$t("views.templates.edittemplate.editor.saveTemplateTitle"),
              {
                color: "editor",
                yesLabel: that.$t("views.templates.edittemplate.buttons.save"),
              },
              {
                showCheckEditionForm: setupConf.existsElement(false, "form"),
              }
            )
            .then((accept) => {
              if (accept.result) {
                that.template.name = accept.name;
                that.template.description = accept.description;
                that.template.expirationDays = accept.expirationDays;
                that.template.expirationDate = accept.expirationDate;
                that.template.blockFormEdition = accept.blockFormEdition;
                that.template.html = editor.getHtml();
                that.template.css = editor.getCss({
                  avoidProtected: true,
                });
                that.save(that.template);
              }
            });
        } else {
          that.template.html = editor.getHtml();
          that.template.css = editor.getCss({
            avoidProtected: true,
          });
          that.save(that.template);
        }
      },
    });
  }

  addCommandExportToEditor() {
    let that = this.editTemplate;
    let editor = this.editor;

    editor.Commands.add("export-template", {
      run(editor) {
        let content = `<!DOCTYPE html><html lang="es"><head><title>${
          that.template.name
        }</title><meta http-equiv="Content-Type" content="text/html; charset=utf-8"/><style>${editor.getCss(
          { avoidProtected: true }
        )}</style></head><body>${editor.getHtml()}</body></html>`;
        let fileName = `${that.template.name}.html`;
        Filesaver.saveAs(
          new Blob([content], {
            type: "text/plain;charset=utf-8",
          }),
          fileName
        );
      },
    });
  }

  addCommandEditDataToEditor() {
    let that = this.editTemplate;
    let editor = this.editor;
    let setupConf = this;
    editor.Commands.add("editDataTemplate", {
      run: function(editor, sender) {
        that
          .$templateData(
            null,
            that.$t("views.templates.edittemplate.editor.editTemplateTitle"),
            {
              color: "editor",
              yesLabel: that.$t("views.templates.edittemplate.buttons.save"),
              noLabel: that.$t("views.templates.edittemplate.buttons.cancel"),
            },
            {
              name: that.template.name,
              description: that.template.description,
              expirationDays: that.template.expirationDays,
              expirationDate: that.template.expirationDate,
              showCheckEditionForm: setupConf.existsElement(false, "form"),
              blockFormEdition: that.template.blockFormEdition,
            }
          )
          .then((accept) => {
            if (accept.result) {
              that.template.name = accept.name;
              that.template.description = accept.description;
              that.template.expirationDays = accept.expirationDays;
              that.template.expirationDate = accept.expirationDate;
              that.template.blockFormEdition = accept.blockFormEdition;
              that.save(that.template);
            }
          });
      },
    });
  }

  addCommandMetaTagsToEditor() {
    let that = this.editTemplate;
    let editor = this.editor;
    editor.Commands.add("editMetaTags", {
      run: function(editor, sender) {
        that
          .$templateMetaTags(
            null,
            that.$t("views.templates.edittemplate.editor.editTemplateMetaTags"),
            {
              color: "editor",
              yesLabel: that.$t("views.templates.edittemplate.buttons.save"),
              noLabel: that.$t("views.templates.edittemplate.buttons.cancel"),
            },
            {
              templateMetadata: that.template.templateMetadata,
            }
          )
          .then((accept) => {
            if (accept.result) {
              that.template.templateMetadata = accept.data;
              that.save(that.template);
            }
          });
      },
    });
  }

  addRichTextToEditor(objConf) {
    this.editor.RichTextEditor.remove(objConf.id);
    this.editor.RichTextEditor.add(objConf.id, {
      name: objConf.name,
      icon: objConf.icon,
      event: objConf.event,
      attributes: objConf.attributes,
      result: objConf.result,
    });
  }

  existsElement(checkTypeDropped, elements, typeDropped) {
    let exists = false;
    this.editor.DomComponents.getWrapper().onAll((comp) => {
      if (elements.indexOf(comp.attributes.type) > -1) {
        exists = true;
      }
      if (checkTypeDropped) {
        if (exists && elements.indexOf(typeDropped) > -1) {
          exists = true;
        } else {
          exists = false;
        }
      }
    });
    return exists;
  }

  addCheckElement(elements, msg) {
    let that = this.editTemplate;
    let existsElement;

    this.editor.on("block:drag:start", (component) => {
      existsElement = this.existsElement(false, elements) ? true : false;
    });

    this.editor.on("block:drag:stop", (component) => {
      if (
        existsElement &&
        component &&
        this.existsElement(true, elements, component.attributes.type)
      ) {
        that.info(msg);
        this.editor.runCommand("core:component-delete", {
          component,
        });
      }
    });
  }

  addDomComponents() {
    this.editor.DomComponents.addType("input", {
      isComponent: (el) => el.tagName == "INPUT",
      model: {
        defaults: {
          traits: [
            "name",
            "placeholder",
            {
              type: "select",
              label: "Type",
              name: "type",
              options: [
                {
                  id: "text",
                  name: "Text",
                },
                {
                  id: "email",
                  name: "Email",
                },
                {
                  id: "password",
                  name: "Password",
                },
                {
                  id: "number",
                  name: "Number",
                },
                {
                  id: "date",
                  name: "Date",
                },
                {
                  id: "time",
                  name: "Time",
                },
              ],
            },
            {
              type: "checkbox",
              name: "required",
            },
            {
              type: "checkbox",
              name: "readonly",
            },
          ],
        },
      },
    });
    this.editor.DomComponents.addType("select", {
      isComponent: (el) => el.tagName == "SELECT",
      model: {
        defaults: {
          traits: [
            "name",
            {
              type: "select-options",
              label: "Options",
              name: "options",
            },
            {
              type: "checkbox",
              name: "required",
            },
            {
              type: "checkbox",
              name: "multiple",
            },
          ],
        },
      },
    });

    this.editor.DomComponents.addType("chat", {
      isComponent: (el) => el.tagName === "CHAT",
      model: {
        defaults: {
          tagName: "chat",
          droppable: false, // Can't drop other elements inside
          traits: [
            {
              type: "select",
              name: "flujo",
              label: "Flujo",
              options: [
                {
                  name: "RGPD",
                  value: "#RGPD_DATA",
                },
              ],
            },
            {
              type: "color",
              label: "Color botones",
              name: "color",
            },
          ],
        },
      },
    });

    this.editor.DomComponents.addType("social", {
      isComponent: (el) => el.tagName === "SOCIAL",
      model: {
        defaults: {
          tagName: "social",
          droppable: false,
          traits: [],
        },
      },
    });

    this.editor.DomComponents.addType("metaTags", {
      isComponent: (el) => el.tagName === "METATAGS",
      model: {
        defaults: {
          tagName: "metaTags",
          droppable: false,
          traits: [
            {
              name: "msgTitle",
              label: "Título en mensaje",
            },
            {
              type: "select",
              name: "msgImage",
              label: "Imagen en mensaje",
              options: [
                {
                  name: "RGPD",
                  value: "0",
                },
              ],
            },
          ],
        },
      },
    });
  }
}
