export const configStyleManager = [
  {
    name: "General",
    buildProps: [
      "float",
      "display",
      "position",
      "top",
      "right",
      "left",
      "bottom",
      "text-align",
    ],
    properties: [
      {
        name: "Alignment",
        property: "float",
        type: "radio",
        defaults: "none",
        list: [
          { value: "none", className: "fa fa-times" },
          { value: "left", className: "fa fa-align-left" },
          { value: "center", className: "fa fa-align-center" },
          { value: "right", className: "fa fa-align-right" },
        ],
      },
      { property: "position", type: "select" },
    ],
  },
  {
    name: "Dimesiones",
    open: false,
    buildProps: [
      "width",
      "flex-width",
      "height",
      "max-width",
      "min-height",
      "margin",
      "padding",
    ],
    properties: [
      {
        id: "flex-width",
        type: "integer",
        name: "Width",
        units: ["px", "%"],
        property: "flex-basis",
        toRequire: 1,
      },
      {
        property: "margin",
        properties: [
          { name: "Top", property: "margin-top" },
          { name: "Right", property: "margin-right" },
          { name: "Bottom", property: "margin-bottom" },
          { name: "Left", property: "margin-left" },
        ],
      },
      {
        property: "padding",
        properties: [
          { name: "Top", property: "padding-top" },
          { name: "Right", property: "padding-right" },
          { name: "Bottom", property: "padding-bottom" },
          { name: "Left", property: "padding-left" },
        ],
      },
    ],
  },
  {
    name: "Tipografía",
    open: false,
    buildProps: [
      "font-family",
      "font-size",
      "font-weight",
      "letter-spacing",
      "color",
      "line-height",
      "text-align",
      "text-decoration",
      "text-shadow",
    ],
    properties: [
      {
        name: "Font",
        property: "font-family",
        list: [
          { name: "Roboto", value: "Roboto, sans-serif" },
          { name: "Raleway", value: "Raleway, sans-serif" },
          { name: "Montserrat", value: "Montserrat, sans-serif" },
          { name: "Poppins", value: "Poppins, sans-serif" },
          {
            name: "Open Sans Condensed",
            value: "Open Sans Condensed, sans-serif",
          },
          { name: "PT Sans Narrow", value: "PT Sans Narrow, sans-serif" },
          { name: "Bitter", value: "Bitter, serif" },
          { name: "Anton", value: "Anton, sans-serif" },
          { name: "Source Code Pro", value: "Source Code Pro, monospace" },
          { name: "Lobster", value: "Lobster, cursive" },
          { name: "Spicy Rice", value: "Spicy Rice, cursive" },
          { name: "Pacifico", value: "Pacifico, cursive" },
          { name: "Indie Flower", value: "Indie Flower, cursive" },
          { name: "Kanit", value: "Kanit, sans-serif" },
          { name: "Teko", value: "Teko, sans-serif" },
          { name: "Shadows Into Light", value: "Shadows Into Light, cursive" },
          { name: "Abril Fatface", value: "Abril Fatface, cursive" },
          { name: "Exo", value: "Exo, sans-serif" },
          { name: "Amatic SC", value: "Amatic SC, cursive" },
          { name: "Fredoka One", value: "Fredoka One, cursive" },
          { name: "Caveat", value: "Caveat, cursive" },
        ],
      },
      { name: "Weight", property: "font-weight" },
      { name: "Font color", property: "color" },
      {
        property: "text-align",
        type: "radio",
        defaults: "left",
        list: [
          {
            value: "left",
            name: "Left",
            className: "fa fa-align-left",
          },
          {
            value: "center",
            name: "Center",
            className: "fa fa-align-center",
          },
          {
            value: "right",
            name: "Right",
            className: "fa fa-align-right",
          },
          {
            value: "justify",
            name: "Justify",
            className: "fa fa-align-justify",
          },
        ],
      },
      {
        property: "text-decoration",
        type: "radio",
        defaults: "none",
        list: [
          { value: "none", name: "None", className: "fa fa-times" },
          {
            value: "underline",
            name: "underline",
            className: "fa fa-underline",
          },
          {
            value: "line-through",
            name: "Line-through",
            className: "fa fa-strikethrough",
          },
        ],
      },
      {
        property: "text-shadow",
        properties: [
          { name: "X position", property: "text-shadow-h" },
          { name: "Y position", property: "text-shadow-v" },
          { name: "Blur", property: "text-shadow-blur" },
          { name: "Color", property: "text-shadow-color" },
        ],
      },
    ],
  },
  {
    name: "Decoraciones",
    open: false,
    buildProps: [
      "opacity",
      "background-color",
      "border-radius",
      "border",
      "box-shadow",
      "background",
    ],
    properties: [
      {
        type: "slider",
        property: "opacity",
        defaults: 1,
        step: 0.01,
        max: 1,
        min: 0,
      },
      {
        property: "border-radius",
        properties: [
          { name: "Top", property: "border-top-left-radius" },
          { name: "Right", property: "border-top-right-radius" },
          { name: "Bottom", property: "border-bottom-left-radius" },
          { name: "Left", property: "border-bottom-right-radius" },
        ],
      },
      {
        property: "box-shadow",
        properties: [
          { name: "X position", property: "box-shadow-h" },
          { name: "Y position", property: "box-shadow-v" },
          { name: "Blur", property: "box-shadow-blur" },
          { name: "Spread", property: "box-shadow-spread" },
          { name: "Color", property: "box-shadow-color" },
          { name: "Shadow type", property: "box-shadow-type" },
        ],
      },
      {
        property: "background",
        properties: [
          { name: "Image", property: "background-image" },
          { name: "Repeat", property: "background-repeat" },
          { name: "Position", property: "background-position" },
          { name: "Attachment", property: "background-attachment" },
          { name: "Size", property: "background-size" },
        ],
      },
    ],
  },
  {
    name: "Extra",
    open: false,
    buildProps: ["transition", "perspective", "transform"],
    properties: [
      {
        property: "transition",
        properties: [
          { name: "Property", property: "transition-property" },
          { name: "Duration", property: "transition-duration" },
          { name: "Easing", property: "transition-timing-function" },
        ],
      },
      {
        property: "transform",
        properties: [
          { name: "Rotate X", property: "transform-rotate-x" },
          { name: "Rotate Y", property: "transform-rotate-y" },
          { name: "Rotate Z", property: "transform-rotate-z" },
          { name: "Scale X", property: "transform-scale-x" },
          { name: "Scale Y", property: "transform-scale-y" },
          { name: "Scale Z", property: "transform-scale-z" },
        ],
      },
    ],
  },
  {
    name: "Flex",
    open: false,
    properties: [
      {
        name: "Flex Container",
        property: "display",
        type: "select",
        defaults: "block",
        list: [
          { value: "block", name: "Disable" },
          { value: "flex", name: "Enable" },
        ],
      },
      {
        name: "Flex Parent",
        property: "label-parent-flex",
        type: "integer",
      },
      {
        name: "Direction",
        property: "flex-direction",
        type: "radio",
        defaults: "row",
        list: [
          {
            value: "row",
            name: "Row",
            className: "icons-flex icon-dir-row",
            title: "Row",
          },
          {
            value: "row-reverse",
            name: "Row reverse",
            className: "icons-flex icon-dir-row-rev",
            title: "Row reverse",
          },
          {
            value: "column",
            name: "Column",
            title: "Column",
            className: "icons-flex icon-dir-col",
          },
          {
            value: "column-reverse",
            name: "Column reverse",
            title: "Column reverse",
            className: "icons-flex icon-dir-col-rev",
          },
        ],
      },
      {
        name: "Justify",
        property: "justify-content",
        type: "radio",
        defaults: "flex-start",
        list: [
          {
            value: "flex-start",
            className: "icons-flex icon-just-start",
            title: "Start",
          },
          {
            value: "flex-end",
            title: "End",
            className: "icons-flex icon-just-end",
          },
          {
            value: "space-between",
            title: "Space between",
            className: "icons-flex icon-just-sp-bet",
          },
          {
            value: "space-around",
            title: "Space around",
            className: "icons-flex icon-just-sp-ar",
          },
          {
            value: "center",
            title: "Center",
            className: "icons-flex icon-just-sp-cent",
          },
        ],
      },
      {
        name: "Align",
        property: "align-items",
        type: "radio",
        defaults: "center",
        list: [
          {
            value: "flex-start",
            title: "Start",
            className: "icons-flex icon-al-start",
          },
          {
            value: "flex-end",
            title: "End",
            className: "icons-flex icon-al-end",
          },
          {
            value: "stretch",
            title: "Stretch",
            className: "icons-flex icon-al-str",
          },
          {
            value: "center",
            title: "Center",
            className: "icons-flex icon-al-center",
          },
        ],
      },
      {
        name: "Flex Children",
        property: "label-parent-flex",
        type: "integer",
      },
      {
        name: "Order",
        property: "order",
        type: "integer",
        defaults: 0,
        min: 0,
      },
      {
        name: "Flex",
        property: "flex",
        type: "composite",
        properties: [
          {
            name: "Grow",
            property: "flex-grow",
            type: "integer",
            defaults: 0,
            min: 0,
          },
          {
            name: "Shrink",
            property: "flex-shrink",
            type: "integer",
            defaults: 0,
            min: 0,
          },
          {
            name: "Basis",
            property: "flex-basis",
            type: "integer",
            units: ["px", "%", ""],
            unit: "",
            defaults: "auto",
          },
        ],
      },
      {
        name: "Align",
        property: "align-self",
        type: "radio",
        defaults: "auto",
        list: [
          {
            value: "auto",
            name: "Auto",
          },
          {
            value: "flex-start",
            title: "Start",
            className: "icons-flex icon-al-start",
          },
          {
            value: "flex-end",
            title: "End",
            className: "icons-flex icon-al-end",
          },
          {
            value: "stretch",
            title: "Stretch",
            className: "icons-flex icon-al-str",
          },
          {
            value: "center",
            title: "Center",
            className: "icons-flex icon-al-center",
          },
        ],
      },
    ],
  },
];

export const customFields = {
  "#CLICODIGO": "Código cliente",
  "#TIECODIGO": "Centro cliente",
  "#TIENOMBRE": "Nombre óptica",
  "#TIE_DOMICILIO": "Domicilio óptica",
  "#TIELOCALIDAD": "Localidad óptica",
  "#TIETELEFONO": "Teléfono óptica",
  "#TIEEMAIL": "E-mail óptica",
  "#CLINOMAPE": "Nombre y apellidos del cliente",
  "#CLINOMBRE": "Nombre del cliente",
  "#CLIAPELLIDO1": "Primer apellido del cliente",
  "#CLIAPELLIDO2": "Segundo apellido del cliente",
  "#CLIFPROXREVLENT": "Fecha de la próxima revisión de lentes",
  "#CLIHPROXREVLENT": "Hora de la próxima revisión de lentes",
  "#CLIFPROXREVLENTI": "Fecha de la próxima revisión de lentillas",
  "#CLIHPROXREVLENTI": "Hora de la próxima revisión de lentillas",
  "#CLIDEUDA": "Importe deuda",
  "#TELEFONOSMS": "Teléfono SMS",
  "#IDCLIENTE": "Identificador del cliente",
  "#VEN_REFERENCIA": "Referencia venta",
  "#VEN_DATA": "Fecha venta",
  "#VEN_FECHAPREVISTA": "Fecha prevista",
  "#VEN_IMPNETO": "Importe neto",
  "#VEN_QUANTITATPAGADA": "Importe total pagado",
  "#VEN_IMPPDTE": "Importe pendiente",
  "#FECHA_PROXCITA": "Fecha próxima cita",
  "#HORA_PROXCITA": "Hora próxima cita",
  "#ULTIMA_FECHA_REV": "Fecha última revisión",
  "#ULTIMA_HORA_REV": "Hora última revisión",
};
